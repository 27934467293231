import { render, staticRenderFns } from "./SearchFormOfertaText.vue?vue&type=template&id=6316c981&scoped=true&"
import script from "./SearchFormOfertaText.vue?vue&type=script&lang=ts&"
export * from "./SearchFormOfertaText.vue?vue&type=script&lang=ts&"
import style0 from "./SearchFormOfertaText.vue?vue&type=style&index=0&id=6316c981&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6316c981",
  null
  
)

export default component.exports